<template>
  <div class="create-affiliate-page">
    <BackTitle confirm-click @click="goBack"> Create affiliate </BackTitle>
    <AffiliateForm
      :value="affiliate"
      submitButtonText="Add"
      :isSubmitting="isSubmitting"
      @submit="save"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle.vue";
import AffiliateForm from "@/components/affiliates/AffiliateForm.vue";
import { NEW_AFFILIATE_MOCK } from "@/helpers/mocks";
import { mapActions } from "vuex";

export default {
  name: "CreateAffiliatePage",
  components: { AffiliateForm, BackTitle },
  data() {
    return {
      affiliate: {
        ...NEW_AFFILIATE_MOCK,
        address: {
          ...NEW_AFFILIATE_MOCK.address,
        },
      },
      isSubmitting: false,
    };
  },
  methods: {
    ...mapActions({
      createAffiliate: "affiliates/createAffiliate",
    }),
    async save(data) {
      try {
        this.isSubmitting = true;
        await this.createAffiliate(data);
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
    goBack() {
      this.$router.push({
        name: "Affiliates",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.create-affiliate-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
