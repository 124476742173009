<template>
  <Form class="affiliate-form" v-slot="{ invalid, changed }">
    <div class="grid-2">
      <FormItem rules="required" v-slot="{ isError }">
        <Input
          label="Name"
          v-model="form.name"
          placeholder="Name"
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Input
          label="Business name"
          v-model="form.businessName"
          placeholder="Business name"
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Select
          label="Country"
          v-model="form.address.country"
          placeholder="Country"
          :options="countryOptions"
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Input
          label="State/Province"
          v-model="form.address.state"
          placeholder="State"
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Input
          label="City"
          v-model="form.address.city"
          placeholder="City"
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Input
          label="Zip code"
          v-model="form.address.zip"
          placeholder="Zip code"
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Input
          label="Street address"
          v-model="form.address.street"
          placeholder="Street address"
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required|decimal:2" v-slot="{ isError }">
        <Input
          label="Commission"
          v-model="form.commission"
          placeholder="Commission"
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Select
          label="Comission type"
          v-model="form.commissionType"
          placeholder="Discount type"
          :is-disabled="isDisabled"
          :options="commissionTypeOptions"
          :is-error="isError"
        />
      </FormItem>
      <FormItem>
        <Select
          label="Products"
          v-model="form.products"
          placeholder="Products"
          :is-disabled="isDisabled"
          :options="productsOptions"
          is-multi
          :tags-limit="['xs'].includes($mq) ? 2 : 5"
        />
      </FormItem>
      <FormItem>
        <Select
          label="Upsell items"
          v-model="form.upsellItems"
          placeholder="UpsellItems"
          :is-disabled="isDisabled"
          :options="upsellItemsOptions"
          is-multi
          :tags-limit="['xs'].includes($mq) ? 2 : 5"
        />
      </FormItem>

      <FormItem class="grid-2__full-item" rules="required">
        <Switcher v-model="form.isActive" label="Is affiliate active?" />
      </FormItem>
    </div>
    <div class="affiliate-form__buttons mt-auto">
      <Button
        v-if="!isDisabled"
        :is-block="['xs', 'sm'].includes($mq)"
        :isDisabled="invalid || !changed || isDeleting"
        :isLoading="isSubmitting"
        @click="handleSubmit"
      >
        {{ submitButtonText }}
      </Button>
      <Guard permission="affiliates.delete" v-slot="{ isAvailable }">
        <Button
          v-if="isAvailable && hasDeleteButton"
          :is-block="['xs', 'sm'].includes($mq)"
          isOutlined
          variant="danger"
          :isDisabled="isSubmitting"
          :isLoading="isDeleting"
          @click="remove"
        >
          Delete affiliate
        </Button>
      </Guard>
    </div>
  </Form>
</template>

<script>
import Guard from "@/components/common/Guard.vue";
import { mapActions, mapState } from "vuex";
import dialog from "@/plugins/dialog";
import { PromoRateTypeEnum } from "@/helpers/enums";
import { getSortedCountries } from "@/helpers/utils";

export default {
  name: "AffiliateForm",
  components: { Guard },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: "Save",
    },
    hasDeleteButton: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        ...this.value,
      },
      isDeleting: false,
      commissionTypeOptions: [
        {
          value: PromoRateTypeEnum.PERCENT,
          name: "Percent",
        },
        {
          value: PromoRateTypeEnum.FIXED,
          name: "Fixed",
        },
      ],
      countryOptions: getSortedCountries().map((country) => ({
        value: country.id,
        name: country.name,
      })),
    };
  },
  computed: {
    ...mapState({
      productsOptions: (state) =>
        state.products.list.map((product) => ({
          value: product.id,
          name: product.name,
        })),
      upsellItemsOptions: (state) =>
        state.upsellItems.list.map((item) => ({
          value: item.id,
          name: item.name,
        })),
    }),
  },
  async created() {
    await Promise.allSettled([this.fetchProducts(), this.fetchUpsellItems()]);
  },
  methods: {
    ...mapActions({
      fetchProducts: "products/fetchProducts",
      fetchUpsellItems: "upsellItems/fetchUpsellItems",
      deleteAffiliate: "affiliates/deleteAffiliate",
    }),
    async remove() {
      const confirmed = await dialog.confirm({
        title: "Delete Affiliate?",
        message: "All affiliate data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isDeleting = true;
          await this.deleteAffiliate(this.$route.params.id);
          await this.$router.push({
            name: "Affiliates",
          });
        } finally {
          this.isDeleting = false;
        }
      }
    },
    handleOpenDatepicker() {
      this.$modal.show("datePeriodModal");
    },
    handleSubmit() {
      this.$emit("submit", {
        ...this.form,
      });
    },
    clearDates() {
      this.dateRange = [];
    },
  },
};
</script>

<style scoped lang="scss">
.affiliate-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 64px;
  height: 100%;

  &__dates {
    position: relative;

    &__clear {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 11px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      gap: 24px;
    }
  }
}
</style>
